import React from 'react';

import * as styles from './styles';
import MentionsCard from './MentionsCard';
import TopicsCompetitorsCard from './TopicsCompetitorsCard';
import SummaryInsightHeader from '../SummaryInsightHeader';
import {
  CallInsightsResponse,
  Mention,
  PositiveNegativeMention,
  TopicsCompetitors,
} from '../../types';
import { useVideoContext } from '../../VideoPlayer/videoPlayer.context';
import { getSectionsToHighlight } from '../../helpers';

type Props = {
  accountName?: string;
  accountId?: string;
  opportunityName?: string;
  accountExecutive?: string;
  opportunityId?: string;
  positiveMentions: PositiveNegativeMention[];
  negativeMentions: PositiveNegativeMention[];
  topics: CallInsightsResponse['topics_discussed'];
  competitors: CallInsightsResponse['competitors_discussed'];
  segments: CallInsightsResponse['segments'];
};

const InsightTab: React.FC<Props> = ({
  accountName,
  accountId,
  opportunityName,
  accountExecutive,
  opportunityId,
  positiveMentions,
  negativeMentions,
  topics,
  competitors,
  segments,
}) => {
  const {
    highlightedName,
    setHighlightedSections,
    setHighlightedName,
    setTime,
    isVideoErrorOrLoading,
  } = useVideoContext();

  const handleClickInsightItem = (startTimes: number[]) => {
    setHighlightedSections(getSectionsToHighlight(startTimes, segments));
  };

  const handleClickTopicCompetitor = (item: TopicsCompetitors) => {
    if (highlightedName === item.text) {
      handleClickInsightItem([]);
      setHighlightedName('');
    } else {
      handleClickInsightItem(item.start_times);
      setHighlightedName(item.text);
    }
  };

  const handleClickMention = (mention: Mention) => {
    if (isVideoErrorOrLoading) return;
    setTime(mention.start_time);
  };

  return (
    <div className={styles.tabContentWrapper}>
      <div className={styles.insightTabWrapper}>
        <SummaryInsightHeader
          accountName={accountName}
          accountId={accountId}
          opportunityName={opportunityName}
          accountExecutive={accountExecutive}
          opportunityId={opportunityId}
        />

        <TopicsCompetitorsCard
          data={topics}
          type="topic"
          handleClickTopicCompetitor={handleClickTopicCompetitor}
        />

        <TopicsCompetitorsCard
          data={competitors}
          type="competitor"
          handleClickTopicCompetitor={handleClickTopicCompetitor}
        />

        {!!positiveMentions && (
          <MentionsCard
            data={positiveMentions}
            mentionType="positive"
            disabled={isVideoErrorOrLoading}
            handleClickMention={handleClickMention}
          />
        )}

        {!!negativeMentions && (
          <MentionsCard
            data={negativeMentions}
            mentionType="negative"
            disabled={isVideoErrorOrLoading}
            handleClickMention={handleClickMention}
          />
        )}
      </div>
    </div>
  );
};

export default InsightTab;
