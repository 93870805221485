import React from 'react';
import classNames from 'classnames';

import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';

import * as styles from './styles';
import { TopicsCompetitors } from '../../types';
import { useVideoContext } from '../../VideoPlayer/videoPlayer.context';

type Props = {
  data: TopicsCompetitors[];
  type: 'topic' | 'competitor';
  handleClickTopicCompetitor: (topicCompetitor: TopicsCompetitors) => void;
};

const TopicsCompetitorsCard: React.FC<Props> = ({
  data,
  type,
  handleClickTopicCompetitor,
}) => {
  const { highlightedName } = useVideoContext();

  const getTopicCompetitorsToolTip = (item: TopicsCompetitors) => (
    <div className={styles.topicCompetitorsTooltipWrapper}>
      <div className="title">
        <span>{item.text}</span> ({item.mentions} mention
        {item.mentions > 1 ? 's' : ''})
      </div>

      {!!item.keywords.length && (
        <div className="keywords">
          <span className="name">Keywords:</span>
          <span>{item.keywords.join(', ')}</span>
        </div>
      )}

      {!!item.sentiment && (
        <div className="sentiment">
          <span className="name">Sentiment:</span>
          <span className={item.sentiment}>{item.sentiment}</span>
        </div>
      )}
    </div>
  );

  return (
    <div className="insight-card">
      <div className="insight-card-title">{`${type}s`}</div>

      <div className="insight-card-content topics">
        {data.length ? (
          data.map((item) => (
            <TooltipWrapper
              tooltip={getTopicCompetitorsToolTip(item)}
              position="bottom center"
              key={item.text}
              hoverable
            >
              <div
                className={classNames('no-ellipsis', {
                  active: highlightedName === item.text,
                })}
                onClick={() => handleClickTopicCompetitor(item)}
              >
                {item.text} ({item.mentions})
              </div>
            </TooltipWrapper>
          ))
        ) : (
          <span>No {type}s found</span>
        )}
      </div>
    </div>
  );
};

export default TopicsCompetitorsCard;
