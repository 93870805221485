import classNames from 'classnames';
import update from 'immutability-helper';
import { isEmpty, isNil, throttle } from 'lodash';
import * as R from 'ramda';
import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { PopupProps, Ref, Table } from 'semantic-ui-react';

import { actions } from 'actions';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuCheckbox from 'components/UI/BuCheckbox';
import BuIcon from 'components/UI/BuIcon';
import TypedHierarchicalRow from 'components/UI/common/TypedTable/TypedHierarchicalRow';
import {
  HIERARCHICAL_OFFSET,
  ICON_WIDTH,
} from 'components/UI/common/TypedTable/TypedHierarchyToggle';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { ButtonsConfig } from 'components/UI/common/TypedTable/renderers/ActionCell';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import * as s from 'components/UI/common/TypedTable/styles';
import { selectableCell } from 'components/UI/common/TypedTable/styles';
import { TableName } from 'components/dashboard/RepsRecommendations/types';
import {
  OnSelectColumn,
  useTableColumnResize,
} from 'components/hooks/useTableColumnResize';
import { splitByNewline } from 'helpers/splitByNewline';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';
import HeaderOptions from './HeaderOptions';
import TypedRow from './TypedRow';
import { getTotalColumns } from './helper';

export enum SortOrder {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

const MAX_STICKY_COLUMNS_WITHOUT_EXTRA_HEADERS_ALLOWED = 4;
const MAX_STICKY_COLUMNS_WITH_EXTRA_HEADERS_ALLOWED = 1;

export type IdType = string | number;
export type ValueProp = null | ValueType | object;
export type ValueType =
  | string
  | number
  | boolean
  | string[]
  | object
  | undefined;
export type ColumnsType = IColumn[] | { [id: string]: IColumn };
export type Status = 'success' | 'loading' | 'error';

export interface TooltipConfig {
  position?: PopupProps['position'];
  relativeFields?: string[];
  getTooltip: (
    value: ValueProp,
    relativeFields: { [key: string]: ValueProp }
  ) => ReactNode;
  hoverable?: boolean;
  relativeField?: string;
}

export type GetFromRowCallback<T> = (
  row: IRow,
  rows: IRow[],
  column?: IColumn
) => T;

export interface TypedTableCellConfig {
  className?: GetFromRowCallback<string> | string;
  sequence?: Array<{ [key: string]: number | string }>;
  formatter?: (...values: ValueProp[]) => React.ReactNode;
  format?: string | 'ago';
  not_available_placeholder?: string;

  /* each cell can have more config parameters */
  buttons?: ButtonsConfig;
  tableName?: TableName;
  truncateTextFrom?: number;
  new?: boolean;
  progressBar?: {
    relativeField: string;
  };
  getClassName?: (value: ValueProp, row: IRow) => string;
  tooltip?: TooltipConfig;
  header?: {
    tooltip?: {
      hoverable?: boolean;
      renderer?: (column: IColumn) => React.ReactNode;
    };
    icon?: React.ReactNode;
  };
  isFormulaColumn?: boolean;
  formulaExpressionDisplayName?: string;
  isMoney?: boolean;
  isNumber?: boolean;
  isMulticurrency?: boolean;
  isCoverage?: boolean;
  isDate?: boolean;
  multiselect?: boolean;
  subValue?: {
    className?: string;
    relativeField?: string; // can be undefined ??
    template?: string; // string format example: '{value}' // can be undefined
    badge?: {
      // can be undefined, relativeField needs to be falsy
      type: string; // 'date' |  'addition' |  'subtraction' | string
      relative_fields: string[];
      template?: string;
    };
    arrow?: {
      //can be undefined
      type: string; // not used
      relative_fields: string[];
    };
    /**
     * Formatter used to transform the value to its format
     * the result of this function will be used as the value
     * to be used over the template
     */
    formatter?: (...values: ValueProp[]) => React.ReactNode;
  };
  highlightText?: string;
  popupPosition?:
    | 'top left'
    | 'top right'
    | 'bottom right'
    | 'bottom left'
    | 'right center'
    | 'left center'
    | 'top center'
    | 'bottom center'
    | undefined;
}

export interface IColumn {
  [key: string]: any;
  config: TypedTableCellConfig;
  delta?: boolean;
  /** return string is deprecated for better type checking **/
  disable?: GetFromRowCallback<boolean> | boolean | string;
  editable?: GetFromRowCallback<boolean> | boolean | string;
  extra_field?: string;
  field: string;
  hidden?: boolean;
  id: IdType;
  label: string;
  labelForceWrap?: boolean;
  minWidth?: number;
  maxWidth?: number;
  resize?: boolean;
  sort_order: SortOrder;
  sortable?: boolean;
  sorting?: boolean;
  showTooltip?: boolean;
  type: ColumnTypes;
  width?: number;
  align?: 'left' | 'center' | 'right';
  extraHeader?: string;
  length?: number /* max length for note cells text */;
  advance?: boolean;
}

export interface IRow {
  [key: string]: ValueProp | undefined;
  children?: IRow[];
  isChildrenVisible?: boolean;
  id: IdType;
  index?: string;
  new?: boolean;
  fixed_order?: boolean;
  amount?: Deals.Deal['amount'];
}

export type onChangeCallback = (
  column: IColumn,
  row: IRow,
  newValue: null | ValueType
) => void;

export interface IDataCellProps {
  column: IColumn;
  onChange?: onChangeCallback;
  onDraftChange?: onChangeCallback;
  row: IRow;
  draftRow?: IRow;
  rows: IRow[];
  status?: StatusData;
}
export interface IRowWithChangesSince extends IRow {
  changesSinceCopy?: string;
  changesSinceDate?: string;
}

export type StatusData = {
  date: number;
  status: Status;
  value: any;
  [name: string]: any;
};

export type CellStatus = {
  [fieldName: string]: StatusData;
};

export type RowStatusType = {
  [id: string]: StatusData | CellStatus;
};

export type CRMMetadataType = {
  currency?: string;
};

type Size = {
  height: number;
  width: number;
};

export type TableDetails = {
  columns: Size[];
  rows: Size[];
};

export type OnSelect = (
  changedRows: IRow[],
  checked: boolean,
  checkAllSelected?: boolean
) => void;

type OwnProps = {
  columns: ColumnsType;
  columnsDnD?: boolean;
  data: IRow[];
  draftData?: IRow[];
  emptyTableMessage?: string;
  extraHeader?: ColumnsType;
  fixColumns?: boolean;
  fullscreen?: boolean;
  isModal?: boolean;
  minWidth?: number | string;
  onChange?: onChangeCallback;
  onDraftChange?: onChangeCallback;
  onHeaderHeight?: (height: number) => void;
  onRender?: (details: TableDetails, shadowLeftOffset: number) => void;
  onSelect?: OnSelect;
  onSort?: (sortOrderString?: string) => void;
  rowClassName?: (row: IRow) => string;
  rowStatus?: RowStatusType;
  selectable?: false | string;
  sorting?: string | null;
  stickyHeader?: boolean;
  total?: IRow;
  widgetSize?: boolean | undefined;
  width?: number | string;
  className?: string;
  sidePanel?: boolean;
  enableReorderingRows?: boolean;
  hideColumnHeader?: boolean;
  canDragRow?(row: IRow): boolean;
  onDrop?(rows: IRow[], row: IRow): void;
  stickyColumnRollUps?: boolean;
  loading?: boolean;
  columnsPinned?: number;
  /**
   * If no onPinChange is provided, the table will not show the pin icon
   * so the user can't pin columns
   */
  onPinChange?: OnPinCallback;
};

type StateProps = {
  persistName: string;
  sequenceColumns?: string[];
  showOrHideColumns?: { label: string; id: string; show: boolean }[];
  sizeColumns?: { width: string; id: string }[] | [];
};

type DispatchProps = {
  handleSequenceColumns?: Function;
};

type ITableProps = OwnProps & StateProps & DispatchProps;

type OnSortCallback = (column: IColumn, order: SortOrder) => void;

export type OnPinCallback = (column: IColumn) => void;

type RefItem = {
  el: HTMLElement;
  columnIndex: number;
};

type Refs = {
  isHeaderSet: boolean;
  rfs: { [key: string]: RefItem };
  resizeObserver: ResizeObserver | null;
  tableDetails: TableDetails;
};

type SortingData = {
  columnName?: string | null;
  sortOrder: SortOrder;
};

type ColumnProps = {
  checkbox: boolean;
  checkboxState: boolean | undefined;
  column: IColumn;
  columnIndex: number;
  columnsDnD: boolean;
  dragEndHandler: Function;
  dragOverHandler: Function;
  dragStartHandler: Function;
  dropHandler: Function;
  isExtraHeader?: boolean;
  maxLevel: number;
  onSelect(checked: boolean): void;
  onSelectColumn: OnSelectColumn;
  onSort: OnSortCallback;
  onPin: OnPinCallback;
  showOrHideColumns?: { label: string; id: string; show: boolean }[];
  sizeColumns: { [key: string]: string }[] | [];
  sorting: SortingData;
  fixColumns?: boolean;
  className?: string;
  isPinned?: boolean;
  isPinnable?: boolean;
};

export const getHierarchicalStyle = (
  column: IColumn,
  columnIndex: number,
  maxLevel: number
) => {
  const additionalWidth =
    columnIndex === 0 ? (HIERARCHICAL_OFFSET + ICON_WIDTH + 10) * maxLevel : 0;

  return {
    maxWidth: column.maxWidth && column.maxWidth + additionalWidth,
    minWidth: column.minWidth && column.minWidth + additionalWidth,
    width: column.width && column.width + additionalWidth,
  };
};

const THROTTLED_RESIZE = 200; // ms

const addIds = <T extends object>(array: T[]) =>
  array.map((item: T, index: number) => ({
    id: index,
    ...item,
  }));

const getSortOrderData = (sortOrderString: string | null): SortingData =>
  sortOrderString
    ? {
        sortOrder:
          sortOrderString.slice(0, 1) === '-'
            ? SortOrder.DESCENDING
            : SortOrder.ASCENDING,
        columnName:
          sortOrderString.slice(0, 1) === '-'
            ? sortOrderString.slice(1)
            : sortOrderString,
      }
    : {
        sortOrder: SortOrder.ASCENDING,
        columnName: null,
      };

const getSortOrderString = (
  column: IColumn | undefined,
  sortOrder?: SortOrder
) => {
  const fieldName = column && (column.extra_field || column.field);
  return `${
    sortOrder && sortOrder === SortOrder.DESCENDING ? '-' : ''
  }${fieldName}`;
};

const Column: React.FC<ColumnProps> = ({
  checkbox,
  checkboxState,
  children,
  column,
  columnIndex,
  columnsDnD,
  dragEndHandler,
  dragOverHandler,
  dragStartHandler,
  dropHandler,
  isExtraHeader,
  maxLevel,
  isPinned,
  isPinnable,
  onSelect,
  onSelectColumn,
  onSort,
  onPin,
  showOrHideColumns,
  sizeColumns,
  sorting,
  fixColumns,
  className,
}) => {
  const refColumn = useRef<HTMLTableHeaderCellElement>(null);
  const [isFirstLoad, setFirstLoad] = useState<boolean>(true);

  const columnIsSorted =
    sorting &&
    (sorting.columnName === column.field ||
      sorting.columnName === column.extra_field);

  const sortDirection = columnIsSorted ? sorting.sortOrder : undefined;

  const handleSort = useCallback(
    (explicitSort?: SortOrder) => {
      if (column.sortable) {
        if (explicitSort) {
          onSort(column, explicitSort);
          return;
        }

        const flippedSort =
          sortDirection === SortOrder.ASCENDING
            ? SortOrder.DESCENDING
            : SortOrder.ASCENDING;

        onSort(column, flippedSort);
      }
    },
    [column.sortable, onSort, sortDirection, columnIsSorted, column]
  );

  const handlePin = () => {
    if (isPinnable && onPin) {
      onPin(column);
    }
  };

  const currentColumn = refColumn.current;
  const columnIdxString = columnIndex.toString();

  useEffect(() => {
    if (currentColumn) {
      currentColumn.style.zIndex = `${130 - columnIndex}`;
    }
  });

  useEffect(() => {
    if (sizeColumns && currentColumn && isFirstLoad) {
      const initialWidth = sizeColumns.find((i) => i.id === column.label);

      if (initialWidth) {
        currentColumn.style.minWidth = initialWidth.width;
        setFirstLoad(false);
      }
    }
  });

  const onMouseDown = useCallback(
    (e: React.MouseEvent) => {
      if (currentColumn !== null) {
        onSelectColumn({
          ref: currentColumn,
          pageX: e.pageX,
          width: columnIdxString,
          id: column.label,
        });
      }
    },
    [currentColumn, columnIdxString]
  );

  const showOrHideColumn =
    showOrHideColumns &&
    showOrHideColumns.find((i) => i.label === children)?.show;

  const showOrHide = showOrHideColumn !== undefined ? showOrHideColumn : true;
  const disableTooltip = typeof children !== 'string';

  return (
    <th
      ref={refColumn}
      className={classNames(
        s.typedTableHeader,
        column.config.className,
        {
          sorting:
            column.sortable &&
            (sorting.columnName === column.field ||
              sorting.columnName === column.extra_field),
          extraHeader: isExtraHeader,
          selectAllContainer: checkbox,
          [s.fieldHeaderHighlight]: !!column.fieldHeaderHighlight,
        },
        className
      )}
      id={column.id.toString()}
      style={getHierarchicalStyle(column, columnIndex, maxLevel)}
      colSpan={column.colSpan || 1}
    >
      {checkbox ? (
        <div>
          <BuCheckbox
            checked={checkboxState}
            indeterminate={checkboxState === undefined}
            onChange={(checked) => {
              onSelect(Boolean(checked));
            }}
          />
        </div>
      ) : (
        <div
          className={classNames('header-content', {
            'fix-position': fixColumns,
            'column-sortable': column.sortable,
            'align-center': column.align === 'center',
            'align-right': column.align === 'right',
            [s.headerSelectableContainer]: checkbox && columnIndex === 0,
          })}
          draggable={columnsDnD}
          onDragStart={(e) => dragStartHandler(e, { ...column, columnIndex })}
          onDragLeave={(e) => dragEndHandler(e)}
          onDragEnd={(e) => dragEndHandler(e)}
          onDragOver={(e) => dragOverHandler(e)}
          onDrop={(e) => dropHandler(e, { ...column, columnIndex })}
        >
          <div className={s.labelAndSortingSection}>
            <div className="table-header-label">
              <TooltipWrapper
                tooltip={
                  column.config.header?.tooltip?.renderer
                    ? column.config.header?.tooltip?.renderer(column)
                    : children
                }
                position="top center"
                popupClassName={
                  !column.config.header?.tooltip ? s.tooltipContent : ''
                }
                disable={disableTooltip}
                hoverable={column.config.header?.tooltip?.hoverable}
              >
                <div className={classNames(s.columnHeaderTitleWrapper)}>
                  <span className={classNames(s.columnHeaderCell)}>
                    {children}
                  </span>
                  {!!column.config.header?.icon && column.config.header?.icon}
                </div>
              </TooltipWrapper>
            </div>
            {column.sortable && sortDirection && (
              <BuIcon
                name={
                  sortDirection === SortOrder.ASCENDING
                    ? BoostUpIcons.SortingAscending
                    : BoostUpIcons.SortingDescending
                }
              />
            )}
          </div>

          <HeaderOptions
            isSortable={!!column.sortable}
            isPinnable={isPinnable}
            sortDirection={sortDirection}
            isPinned={isPinned}
            onSort={handleSort}
            onPin={handlePin}
          />
        </div>
      )}
      {columnsDnD && (
        <span onMouseDown={onMouseDown} className={s.resize__line} />
      )}
    </th>
  );
};

const TypedTable = ({
  columns,
  columnsDnD = false,
  data: rawData,
  draftData = [],
  emptyTableMessage = 'Nothing found',
  extraHeader,
  fixColumns = false,
  fullscreen = false,
  handleSequenceColumns,
  minWidth,
  onChange = () => {},
  onDraftChange,
  onHeaderHeight = () => {},
  onRender = () => {},
  onSelect = () => {},
  onSort = () => {},
  persistName,
  rowClassName = () => '',
  rowStatus = {},
  selectable = false,
  sequenceColumns,
  showOrHideColumns,
  sizeColumns,
  sorting = null,
  columnsPinned = 0,
  stickyHeader = false,
  total,
  widgetSize,
  width,
  className,
  enableReorderingRows = false,
  hideColumnHeader = false,
  canDragRow = () => true,
  onDrop = () => {},
  stickyColumnRollUps,
  loading,
  onPinChange,
}: ITableProps) => {
  const refs = useRef<Refs>({
    isHeaderSet: false,
    rfs: {},
    resizeObserver: null,
    tableDetails: {
      columns: [],
      rows: [],
    },
  });
  const [columnList, setColumnList] = useState<IColumn[]>([]);
  const [extraHeaderList, setExtraHeaderList] = useState<IColumn[]>([]);
  // const [listOrder, setListOrder] = useState<string | null>(null);
  // const [listOrderColumn, setListOrderColumn] = useState<IdType | null>(null);
  const [showMoreDetailsVisible, setShowMoreDetailsVisible] = useState<{
    [key: string]: boolean;
  }>({});
  const [currentDragColumn, setCurrentDragColumn] = useState<IColumn>();
  const [data, setData] = useState<IRow[]>([]);
  const onSelectColumn = useTableColumnResize();
  const visibleColumns = columnList.filter(
    (column) =>
      // Configuration that comes from column configuration
      !column.hidden &&
      // Configuration that comes from the show / hide columns dropdown
      showOrHideColumns?.find((i) => i.id === column.id)?.show !== false
  );

  const columnsLength = visibleColumns.length;
  const sortingData = getSortOrderData(sorting);

  const [forceUpdateTableLeftOffset, setForceUpdateTableLeftOffset] = useState<
    string | null
  >(null);
  const [highlightRow, setCurrentHoveringRowIndex] = useState<number | null>(
    null
  );
  const [topOrBottomHighlight, setRowHighlightPlace] = useState<
    'bottom' | 'top'
  >('bottom');

  const sRawData = JSON.stringify(rawData);

  const maxLevel = useMemo(
    () =>
      rawData.reduce<number>((acc, row) => {
        const recursiveCunt = (children: IRow[], level: number) => {
          children.forEach((curr) => {
            if (curr.children) {
              const nextLevel = level + 1;

              if (nextLevel > acc) {
                acc = nextLevel;
              }

              recursiveCunt(curr.children, nextLevel);
            }
          });
        };

        if (row?.children) {
          recursiveCunt(row?.children, 1);
        }

        return acc;
      }, 0),
    [sRawData]
  );

  useEffect(() => {
    setData(addIds(rawData));
  }, [sRawData]);

  const handleSort = useCallback(
    (column: IColumn, order: SortOrder) => {
      // setListOrder(order);
      // setListOrderColumn(column.id);

      if (onSort instanceof Function) {
        onSort(getSortOrderString(column, order));
      }
    },
    [onSort]
  );

  const handleHeaderRef = useCallback(
    (el: HTMLElement) => {
      if (!refs.current.isHeaderSet && el && stickyHeader) {
        onHeaderHeight(el.clientHeight || 0);
        refs.current.isHeaderSet = true;
      }
    },
    [refs.current.isHeaderSet]
  );

  const resizeHandler = (entries: ResizeObserverEntry[]) => {
    const changedColumns: Size[] = [];

    for (const entry of entries) {
      const refItem = refs.current.rfs[entry.target.id];
      const columnIndex = refItem?.columnIndex;
      const storedSize = refs.current.tableDetails.columns[columnIndex];
      const currentSize = entry.target.getBoundingClientRect();

      if (
        !storedSize ||
        storedSize.width !== currentSize.width ||
        storedSize.height !== currentSize.height
      ) {
        changedColumns[columnIndex] = {
          width: currentSize.width,
          height: currentSize.height,
        };
      }
    }

    if (changedColumns.length) {
      refs.current.tableDetails = {
        ...refs.current.tableDetails,
        columns: refs.current.tableDetails.columns.map(
          (c, i) => changedColumns[i] || c
        ),
      };

      setForceUpdateTableLeftOffset(Date.now().toString());
    }
  };

  const throttledResizeHandler = useCallback(
    throttle<ResizeObserverCallback>(
      (entries: ResizeObserverEntry[]) => resizeHandler(entries),
      THROTTLED_RESIZE
    ),
    []
  );

  const setColumnRef = useCallback(
    (el: HTMLElement) => {
      if (el) {
        const columnIndex = visibleColumns.findIndex((c) => c.id === el.id);

        if (columnIndex > -1) {
          refs.current.rfs[el.id] = {
            el,
            columnIndex,
          };

          const columns = [...refs.current.tableDetails.columns];
          const currentSize = el.getBoundingClientRect();

          columns[columnIndex] = {
            width: currentSize.width,
            height: currentSize.height,
          };

          refs.current.tableDetails = {
            ...refs.current.tableDetails,
            columns,
          };

          refs.current.resizeObserver!.observe(el);
        }
      }
    },
    [columnList]
  );

  useEffect(() => {
    const shadowLeftOffset = getHowMuchStickToLeft(columnsPinned);
    onRender(refs.current.tableDetails, shadowLeftOffset);
  }, [columnList, forceUpdateTableLeftOffset]);

  useEffect(() => {
    const shadowLeftOffset = getHowMuchStickToLeft(columnsPinned);
    onRender(refs.current.tableDetails, shadowLeftOffset);
  }, [columnsPinned, visibleColumns]);

  useEffect(() => {
    refs.current.resizeObserver = new ResizeObserver(throttledResizeHandler);

    return () => {
      if (!refs.current.resizeObserver) {
        return;
      }

      const refEntries = refs.current.rfs
        ? Object.entries(refs.current.rfs)
        : [];

      refEntries.forEach(([_, value]) => {
        refs.current.resizeObserver!.unobserve(value.el);
      });

      refs.current.resizeObserver.disconnect();
      refs.current.resizeObserver = null;
    };
  }, []);

  const handleCheckAll = (checked: boolean) => {
    onSelect(
      data.filter((row) => !isDisable(visibleColumns[0], row, data)),
      checked,
      true
    );
  };

  const getHeaderCheckboxState = useCallback(() => {
    if (selectable && visibleColumns.length) {
      const list = data
        .filter((row) => !isDisable(visibleColumns[0], row, data))
        .map((row) => row[selectable]);

      const oneIsUnchecked = list.findIndex((value) => !value) >= 0;
      const oneIsChecked = list.findIndex((value) => value) >= 0;

      return oneIsUnchecked && oneIsChecked ? undefined : !oneIsUnchecked;
    }

    return false;
  }, [visibleColumns]);

  useEffect(() => {
    if (columns instanceof Array) {
      if (
        !isNil(sequenceColumns) &&
        !isEmpty(sequenceColumns) &&
        !isEmpty(columns)
      ) {
        let newColumns: IColumn[] = [];

        const isCustomSequenceValid = sequenceColumns.every((id) => {
          const column = columns.find((elem) => elem.id === id);
          return column ? newColumns.push(column) : false;
        });

        if (isCustomSequenceValid) {
          setColumnList(newColumns);
        } else {
          setColumnList(columns as IColumn[]);
          handleSequenceColumns &&
            handleSequenceColumns({ persistName, columns: [] });
        }
      } else {
        setColumnList(columns as IColumn[]);
      }
    } else {
      setColumnList(
        addIds(
          R.map(
            ([key, value]) => ({ ...value, id: value.id || key }),
            R.toPairs<IColumn>(columns)
          )
        )
      );
    }
  }, [JSON.stringify(columns), JSON.stringify(sequenceColumns)]);

  useEffect(() => {
    if (extraHeader && !(extraHeader instanceof Array)) {
      setExtraHeaderList(
        addIds(
          R.map(
            ([key, value]) => ({ ...value, id: value.id || key }),
            R.toPairs<IColumn>(extraHeader)
          )
        )
      );
    } else {
      setExtraHeaderList(extraHeader as IColumn[]);
    }
  }, [extraHeader]);

  const dragStartHandler = (e: React.MouseEvent, column: IColumn) => {
    if (!columnsDnD || (maxLevel > 0 && column.columnIndex === 0)) {
      return;
    }

    setCurrentDragColumn(column);
    e.currentTarget.classList.add(s.selectedBg);
  };

  const dragEndHandler = (e: React.MouseEvent) => {
    if (columnsDnD) {
      e.currentTarget.classList.remove(s.selectedBg, s.currentBg);
    }
  };

  const dragOverHandler = (e: React.MouseEvent) => {
    if (columnsDnD) {
      e.preventDefault();
      e.currentTarget.classList.add(s.currentBg);
    }
  };

  type AccInit = { updated: IColumn[]; sequence: string[] };
  const dropHandler = (e: React.MouseEvent, column: IColumn) => {
    e.preventDefault();
    e.currentTarget.classList.remove(s.currentBg);

    if (
      !currentDragColumn ||
      (maxLevel > 0 && column.columnIndex === 0) ||
      currentDragColumn?.extraHeader !== column.extraHeader
    ) {
      return;
    }

    const accInit: AccInit = {
      sequence: [],
      updated: [],
    };

    const listToReduce = maxLevel > 0 ? columnList.slice(1) : columnList;

    if (maxLevel > 0) {
      accInit.sequence.push(`${columnList[0].id}`);
      accInit.updated.push({ ...columnList[0], columnIndex: 0 });
    }

    const list = listToReduce.reduce<AccInit>((acc, curr, index) => {
      if (index === column.columnIndex) {
        acc.sequence.push(`${currentDragColumn.id}`);
        acc.updated.push(currentDragColumn);
        acc.sequence.push(`${column.id}`);
        acc.updated.push(column);
        return acc;
      }

      if (![currentDragColumn.id, column.id].includes(curr.id)) {
        acc.sequence.push(`${curr.id}`);
        acc.updated.push(curr);
      }

      return acc;
    }, accInit);

    setColumnList(list.updated);

    if (!isNil(handleSequenceColumns)) {
      handleSequenceColumns({ path: persistName, columns: list.sequence });
    }
  };

  const findRow = useCallback(
    (id: IRow['id']) => {
      const row = data.find((item) => item.id === id);

      return {
        row: row!,
        index: data.indexOf(row!),
      };
    },
    [data]
  );

  const moveRow = useCallback(
    (id: IRow['id'], atIndex: number) => {
      // Set null to not highlight any row
      setCurrentHoveringRowIndex(null);

      const { row, index } = findRow(id);

      const newRows = update(data, {
        $splice: [
          [index, 1],
          [atIndex, 0, row],
        ],
      });

      // Updating rows with the row moved
      setData(newRows);

      // Callback to signal parent component that there was an on drop
      // This can't be done on handle drop, because rows received as parameter
      // this has old order when called from typedRowDraggable
      onDrop(newRows, row);
    },
    [data, setData, findRow]
  );

  const borderPaintStyle = useCallback(
    (index: number) => {
      const shouldPaint = highlightRow === index;
      const borderPlace =
        topOrBottomHighlight === 'top' ? 'borderTop' : 'borderBottom';

      return {
        [borderPlace]: shouldPaint ? '1.5px solid var(--bu-gray-600)' : '',
      };
    },
    [highlightRow, topOrBottomHighlight]
  );

  const selectableColumnRef = useRef<HTMLElement | null>(null);
  const dragDropColumnRef = useRef<HTMLElement | null>(null);

  const handleSelectableColumnRef = useCallback((node: HTMLElement | null) => {
    selectableColumnRef.current = node;
    if (node) {
      refs.current.resizeObserver!.observe(node);
    }
  }, []);

  const handleDragDropColumnRef = useCallback((node: HTMLElement | null) => {
    dragDropColumnRef.current = node;
    if (node) {
      refs.current.resizeObserver!.observe(node);
    }
  }, []);

  const tableHeaderRef = useRef<HTMLTableSectionElement>(null);

  const tableHeaderHeight = tableHeaderRef.current?.clientHeight;

  const getHowMuchStickToLeft = (columnIndex: number) => {
    const columns = refs.current.tableDetails.columns;

    const dragDropColumnWidth = dragDropColumnRef?.current?.offsetWidth || 0;
    const selectableColumnWidth =
      selectableColumnRef?.current?.offsetWidth || 0;

    const initialWidth = dragDropColumnWidth + selectableColumnWidth;

    if (columnIndex === 0) {
      return initialWidth;
    }

    return columns
      .slice(0, columnIndex)
      .reduce((acc, curr) => acc + (curr?.width || 0), initialWidth);
  };

  const hasExtraHeader = extraHeaderList && extraHeaderList.length > 0;

  const getHowManyColumnsToPinWithExtraHeader = (
    extraHeaderList: IColumn[]
  ) => {
    const firstExtraHeader = extraHeaderList[0];
    const extraHeaderSpanIsOnlyOne = firstExtraHeader.colSpan === 1;
    if (extraHeaderSpanIsOnlyOne) {
      return 1;
    }

    return 0;
  };

  const areColumnsPinnable = columnsPinned > 0;

  let validPinnedColumns = columnsPinned;
  let maxAllowedColumnsPinned = 0;
  let areValidColumnsPinned = false;
  const userCanPinColumns = !!onPinChange;

  if (areColumnsPinnable) {
    const extraHeaderDependentPinnedColumns = hasExtraHeader
      ? getHowManyColumnsToPinWithExtraHeader(extraHeaderList)
      : columnsPinned;

    maxAllowedColumnsPinned = hasExtraHeader
      ? MAX_STICKY_COLUMNS_WITH_EXTRA_HEADERS_ALLOWED
      : MAX_STICKY_COLUMNS_WITHOUT_EXTRA_HEADERS_ALLOWED;

    validPinnedColumns = Math.min(
      extraHeaderDependentPinnedColumns,
      maxAllowedColumnsPinned
    );

    areValidColumnsPinned = validPinnedColumns > 0;
  }

  const getStickyClassIfNeededForColumn = (columnIndex: number) => {
    if (columnIndex < validPinnedColumns) {
      const isLastStickyColumn = columnIndex === columnsPinned - 1;
      return classNames(s.stickCellToLeft(getHowMuchStickToLeft(columnIndex)), {
        [s.lastStickyCell]: isLastStickyColumn,
      });
    }

    return;
  };

  return (
    <>
      <Table
        compact
        fixed
        singleLine
        structured
        className={classNames(
          className,
          'bu-font-default',
          s.tableSizeStyle,
          'table-compact',
          s.getClassStripeColor(widgetSize),
          s.disableDefaultOverflow,
          s.mediaQuery,
          s.table_layout,
          s.hoverRow,
          s.fullTableBorders,
          {
            [s.fixedBody]: fixColumns,
            [s.adaptiveContent]: columnsLength <= 15, //TODO remove adaptive content
            [s.stickyColumnRollUps]: stickyColumnRollUps,
            [s.stickyHeader]: stickyHeader,
          }
        )}
        style={{
          minWidth,
          width,
        }}
      >
        <Ref innerRef={tableHeaderRef}>
          <Table.Header>
            {extraHeaderList && (
              <Ref innerRef={handleHeaderRef}>
                <Table.Row>
                  {extraHeaderList.map((column: IColumn, index: number) => (
                    <Ref
                      innerRef={setColumnRef}
                      key={`column-ref-${column.id}`}
                    >
                      <Table.HeaderCell
                        as={Column}
                        column={column}
                        columnIndex={index}
                        fullscreen={fullscreen}
                        isExtraHeader
                        key={`column-ccc-${column.id}`}
                        maxLevel={maxLevel}
                        onSelectColumn={onSelectColumn}
                        onSort={handleSort}
                        showOrHideColumns={showOrHideColumns}
                        sizeColumns={sizeColumns}
                        sorting={sortingData}
                        fixColumns={fixColumns}
                        className={classNames(
                          // If there are pinned columns with extra headers
                          // if because only the first column is pinned
                          // and the extra header is only spans a single column
                          // So only the first extra header is pinned
                          areValidColumnsPinned &&
                            index === 0 &&
                            s.stickHeaderToLeft(getHowMuchStickToLeft(index))
                        )}
                      >
                        {column.label}
                      </Table.HeaderCell>
                    </Ref>
                  ))}
                </Table.Row>
              </Ref>
            )}

            {!hideColumnHeader && (
              <Ref innerRef={handleHeaderRef}>
                <Table.Row>
                  {enableReorderingRows && (
                    <Ref innerRef={handleDragDropColumnRef}>
                      <Table.HeaderCell
                        style={{ width: '40px', minWidth: '40px' }}
                        key={`column-ccc-dragDrop`}
                        className={s.typedTableHeader}
                      ></Table.HeaderCell>
                    </Ref>
                  )}
                  {visibleColumns.length > 0 && selectable && (
                    <Ref innerRef={handleSelectableColumnRef}>
                      <Table.HeaderCell
                        key={`column-ccc-selectable`}
                        checkbox={!!selectable}
                        checkboxState={getHeaderCheckboxState()}
                        onSelect={handleCheckAll}
                        as={Column}
                        sortable={false}
                        className={selectableCell}
                        column={{
                          field: '',
                          id: 'selectable',
                          label: '',
                          sortable: false,
                          config: {},
                        }}
                        columnIndex={-1}
                      />
                    </Ref>
                  )}
                  {visibleColumns.map((column, index) => (
                    <Ref
                      innerRef={setColumnRef}
                      key={`column-ref-${column.id}`}
                    >
                      <Table.HeaderCell
                        as={Column}
                        column={column}
                        columnIndex={index}
                        columnsDnD={columnsDnD}
                        dragEndHandler={dragEndHandler}
                        dragOverHandler={dragOverHandler}
                        dragStartHandler={dragStartHandler}
                        dropHandler={dropHandler}
                        fullscreen={fullscreen}
                        key={`column-ccc-${column.id}`}
                        maxLevel={maxLevel}
                        onSelectColumn={onSelectColumn}
                        onSort={handleSort}
                        onPin={onPinChange}
                        isPinnable={
                          areValidColumnsPinned &&
                          userCanPinColumns &&
                          index !== 0 &&
                          index < maxAllowedColumnsPinned
                        }
                        showOrHideColumns={showOrHideColumns}
                        sizeColumns={sizeColumns}
                        sorting={sortingData}
                        fixColumns={fixColumns}
                        className={classNames(
                          index < validPinnedColumns &&
                            s.stickHeaderToLeft(getHowMuchStickToLeft(index))
                        )}
                        isPinned={index < validPinnedColumns}
                      >
                        {column.label}
                      </Table.HeaderCell>
                    </Ref>
                  ))}
                </Table.Row>
              </Ref>
            )}
          </Table.Header>
        </Ref>
        {total && (
          <Table.Body
            className={classNames(
              tableHeaderHeight && s.totalRow(tableHeaderHeight)
            )}
          >
            <TypedRow
              row={total}
              visibleColumns={getTotalColumns(visibleColumns, total)}
              maxLevel={0}
              onChange={() => {}}
              rows={[]}
              setShowMoreDetailsVisible={() => {}}
              enableReorderingRows={false}
              moveRow={() => {}}
              setCurrentHoveringRowIndex={() => {}}
              setRowHighlightPlace={() => {}}
              onDrop={() => {}}
              showMoreDetailsVisible={{}}
              findRow={findRow}
              canDragRow={() => {
                return false;
              }}
              getStickyClassIfNeededForColumn={getStickyClassIfNeededForColumn}
            />
          </Table.Body>
        )}

        <Table.Body data-cypress="typed-table-body">
          {data.length > 0 &&
            data.map((row, idx) => (
              <TypedHierarchicalRow
                draftRow={draftData.find((item) => item.id === row.id)}
                key={
                  enableReorderingRows
                    ? `fr-row-${row.id}`
                    : `fr-row-${row.id}-${idx}`
                }
                maxLevel={maxLevel}
                onChange={onChange}
                onDraftChange={onDraftChange}
                onSelect={onSelect}
                row={row}
                rowClassName={rowClassName}
                rowStatus={rowStatus}
                rows={data}
                selectable={selectable}
                setShowMoreDetailsVisible={setShowMoreDetailsVisible}
                showMoreDetailsVisible={showMoreDetailsVisible}
                showOrHideColumns={showOrHideColumns}
                visibleColumns={visibleColumns}
                moveRow={moveRow}
                setCurrentHoveringRowIndex={setCurrentHoveringRowIndex}
                setRowHighlightPlace={setRowHighlightPlace}
                findRow={findRow}
                enableReorderingRows={enableReorderingRows}
                canDragRow={canDragRow}
                onDrop={onDrop}
                hoveringBorderStyle={borderPaintStyle(idx)}
                getStickyClassIfNeededForColumn={
                  getStickyClassIfNeededForColumn
                }
              />
            ))}
        </Table.Body>
      </Table>

      {(!data || data.length === 0) && !loading && (
        <div className={s.emptyTableMessage}>
          {splitByNewline(emptyTableMessage)}
        </div>
      )}
    </>
  );
};

export enum BorderType {
  ALL,
  TOP = 1 << 0,
  RIGHT = 1 << 1,
  BOTTOM = 1 << 2,
  LEFT = 1 << 3,
}

type IBorder = {
  width?: string | number;
  height?: string | number;
  borders?: BorderType;
  className?: string;
};

const Border: React.FC<IBorder> = ({
  children,
  width,
  height,
  borders = BorderType.ALL,
  className,
}: PropsWithChildren<IBorder>) => {
  return (
    <div
      className={classNames('typed-border', className, s.border, {
        top: (borders & BorderType.TOP) === BorderType.TOP,
        right: (borders & BorderType.RIGHT) === BorderType.RIGHT,
        bottom: (borders & BorderType.BOTTOM) === BorderType.BOTTOM,
        left: (borders & BorderType.LEFT) === BorderType.LEFT,
      })}
      style={{ width, height }}
    >
      {children}
    </div>
  );
};

TypedTable.Border = Border;

export const isFunction = <T extends Function>(a: any | T): a is T =>
  typeof a === 'function';

export function isEditable(column: IColumn, row: IRow, rows: IRow[]): boolean {
  switch (typeof column.editable) {
    case 'function':
      return column.editable(row, rows);
    case 'string':
      return Boolean(row[column.editable]);
    case 'boolean':
    default:
      return Boolean(column.editable);
  }
}

export function isDisable(column: IColumn, row: IRow, rows: IRow[]): boolean {
  switch (typeof column.disable) {
    case 'function':
      return column.disable(row, rows);
    case 'string':
      return Boolean(row[column.disable]);
    case 'boolean':
    default:
      return Boolean(column.disable);
  }
}

const mapDispatchToProps: DispatchProps = {
  handleSequenceColumns: actions.ui.appState.setSequenceColumns,
};

const mapStateToProps = (
  state: IReduxState,
  ownProps: OwnProps
): StateProps => ({
  persistName: selectors.getPersistName(state, ownProps.isModal),
  sequenceColumns: selectors.getSequenceColumns(state, ownProps.isModal),
  showOrHideColumns: selectors.getShowOrHideColumns(state, ownProps.isModal),
  sizeColumns: selectors.getSizeColumns(state, ownProps.isModal),
});

export default connect(mapStateToProps, mapDispatchToProps)(TypedTable);
